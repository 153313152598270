<template>
  <div class="main d-flex justify-content-center">
    <div class="terms">
      <section class="mainarea">
        <h2 class="ttl">ReseMom相談online利用規約（本サービス利用者用）</h2>
        <p>
          この利用規約（以下、「本利用規約」といいます）は、株式会社イード（以下、「当社」といいます）が運営する「ReseMom相談online」（以下、「本サービス」といいます）の本サービス利用者と当社との間の権利義務関係に適用されます。
        </p>
        <p>
          なお、本利用規約に加え、ガイドラインやその他のルール（以下、総称して「ルール等」といいます）が定められることがあります。当社が本サービス上で随時掲載する本サービスに関するルール等は、本規約の一部を構成するものとします。
        </p>

        <h3 class="subttl">本利用規約の改定・変更</h3>
        <p>
          当社は必要に応じて、本利用規約を変更できるものとします。変更後の本利用規約は、当該掲載が成された時点で効力が生じるものとし、本サービス利用者が本利用規約発効後に本サービスを利用した場合、本サービス利用者が本利用規約の変更を承諾したものとみなします。
        </p>

        <h3 class="subttl">本サービス</h3>
        <p>
          本サービスは、本サービス利用者に対し、本サービスに登録しカウンセリングサービスを提供する講師（以下、「講師」といいます）が提供する有料相談サービスを提供するものです。当社は、本サービスのシステム及び情報の提供、本サービス利用者からの利用料金の受領業務を行います。本サービス利用者からの個別の相談の申込に関する契約（以下「個別相談契約」といいます）は、本サービス利用者と申込みをした当該講師との間で成立するものとし、当社は当社の責に帰すべき事由による場合を除き、当社は個別相談契約の内容について責任を負いません。
        </p>

        <h3 class="subttl">本サービスの変更・停止・終了</h3>
        <p>
          災害、労働争議、停電、本サービスのシステムの故障、その他のやむを得ない事情、または当社の都合により、本サービス自体または一部を停止することがあります。この場合、当社は本サービスの追加・変更・中断・廃止によって本サービス利用者に不利益または損害が発生したとしても、一切の責任を負いません。また、当社は事前に本サービスの終了を本サービス利用者に通知することができます。
        </p>

        <h3 class="subttl">利用環境の整備</h3>
        <p>
          本サービス利用者は、本サービスを利用するために必要な機器、ソフトウェア、通信手段等を自己の責任と費用で適切に整備するものとします。本サービス利用者が自身の利用環境の故障・中断・停止等の理由により不利益や損害が発生しても、当社は一切責任を負いません。
        </p>

        <h3 class="subttl">利用申込</h3>
        <p>
          本サービスの利用を希望する場合、本利用規約に同意のうえ、当社の定める手続に従い登録を行うものとします。なお、本サービスへの登録は無料とします。
          当社は、下記のいずれかに該当する場合、当社の判断によって登録を承認しないことがあります。
        </p>
        <ol class="decimal">
          <li>当社所定の方法・様式以外で登録申請をした場合</li>
          <li>
            登録内容に虚偽の情報があった場合、又は虚偽である可能性が高いと当社が判断した場合
          </li>
          <li>当社から登録の取消しを受けたことがある場合</li>
          <li>その他、当社が不適切と判断した場合</li>
        </ol>

        <h3 class="subttl">登録の取消し</h3>
        <p>
          当社は、本サービス利用者が以下のいずれかに該当する場合、当社の判断により登録を取り消すことができます。また、登録の取消しに本サービス利用者に不利益や損害が発生しても、当社は一切責任を負いません。
        </p>

        <ol class="decimal">
          <li>当社所定の方法・様式以外で登録申請をした場合</li>
          <li>
            登録内容に虚偽の情報があった場合、又は虚偽である可能性が高いと当社が判断した場合
          </li>
          <li>当社又は講師からの連絡が取れなくなった場合</li>
          <li>当社から登録の取り消しを受けたことがある場合</li>
          <li>本利用規約に違反した場合</li>
          <li>その他、当社が不適切と判断した場合</li>
        </ol>

        <h3 class="subttl">登録情報の変更</h3>
        <p>
          本サービス利用者は、登録情報に変更が生じた場合、当社の指定する方法によって速やかに本システム上で登録情報の変更を行うものとします。本サービス利用者が変更の届出を行わなかったことによる不利益が生じた場合、当社はその一切の責任を負いません。
        </p>

        <h3 class="subttl">退会</h3>
        <p>
          本サービス利用者は、本サービスへの登録終了を希望する場合、当社所定の手続きを行うものものとします。ただし、個別相談契約が終了していない状況や完了した個別相談契約の利用料支払いが完了していない状況では、本サービス利用者と当社との間で協議の上、登録終了の手続きを行うものとします。
        </p>

        <h3 class="subttl">本サービス利用料の支払い</h3>
        <p>
          本サービス利用者は、個別相談契約に定める本サービス利用料を支払うものとします。
        </p>
        <p>
          本サービスの決済手段は、当社が指定する決済サービスを利用したクレジットカードによる支払いのみとします。本サービス利用者による決済手段の利用については、当該決済サービスを提供する第三者が定める利用規約が適用されますので、当該利用規約をご確認ください。
        </p>
        <p>
          本サービス利用者が登録したクレジットカード情報に入力ミス、有効期限切れ、利用限度額超過などの理由にかかわらず、本サービス利用料の決済ができなかった場合、当社または決済サービス提供事業者は本サービス利用料の支払いを督促する場合があります。督促を受けた場合、本サービス利用者は速やかに決済可能なクレジットカード情報の登録など、決済に必要な措置を講じるものとします。
          本サービス利用者による本サービス利用料の支払いが確認できない場合、当社は判断に基づき個別相談契約の解約、又は本サービスの利用登録の取消しをする場合があります。
        </p>

        <h3 class="subttl">ログイン用メールアドレスおよびパスワードの管理</h3>
        <p>
          本サービス利用者は、本サービスを利用するために、不正使用されないログイン用メールアドレスおよびパスワードを登録するものとします。登録されたログイン用メールアドレスおよびパスワードは、本サービス利用者の責任において適切に管理し、第三者に開示または貸与することはできません。当社は、ログイン用メールアドレスおよびパスワードの不正利用によって生じた本サービス利用者の損害について責任を負いません。また、ログイン用メールアドレスおよびパスワードを用いて本サービスへ認証した後の行為本サービス利用者の行為とみなされます。
        </p>

        <h3 class="subttl">権利の帰属</h3>
        <p>
          本サービスの利用契約に基づき、当社が本サービス利用者に本サービス上で提供する情報（記事、写真、動画などを含むが、これに限定しない）、機能、その他一切のサービス関する著作権、商標権等の知的財産権は、当社または当該権利を保有する第三者に帰属します。
        </p>

        <h3 class="subttl">掲載情報の無断使用禁止</h3>
        <p>
          本サービスを介して提供する情報は、本サービス利用者の私的利用の範囲内でご利用いただけます。ただし、当社が承認した場合（該当情報に関して権利を持つ第三者がいる場合は、第三者の承認を含む）を除き、本サービスで提供するいかなる情報も、私的利用の範囲を超えて無断で複製、転載、販売、譲渡、その他の方法において、使用することはできません。また、第三者を利用して同様の行為をさせてはいけません。
        </p>

        <h3 class="subttl">禁止事項</h3>
        <p>
          本サービス利用者は、本サービスの登録や利用において以下の行為を行ってはなりません。
        </p>
        <ol class="decimal">
          <li>
            第三者又は特定の業者・商品を誹謗中傷し、社会的信用を落とす行為
          </li>
          <li>
            第三者の知的財産権（商標権、著作権、意匠権、特許権など）を侵害する行為
          </li>
          <li>第三者の財産、信用、名誉、プライバシーを侵害する行為</li>
          <li>
            暴力的な要求行為、法的な責任を超えた不当な要求行為、脅迫的な言動を用いる行為、又は暴力を用いる行為
          </li>
          <li>犯罪行為の助長、又はその実行を暗示する行為</li>
          <li>
            当社による本サービスの運営を妨げ、当社又は講師の信用を毀損若しくは営業を妨害する行為
          </li>
          <li>公序良俗に反する行為</li>
          <li>法令、これに準ずるガイドライン、準則等に反する行為</li>
          <li>本利用規約に違反する行為</li>
          <li>その他、当社が不適切と判断する行為</li>
        </ol>

        <h3 class="subttl">免責事項</h3>
        <p>
          当社は、本サービスにおける提供情報の正確性、適時性、完全性、有用性、適法性、問題の改善および解決等について一切責任を負いません。本サービス利用者は自己の判断に従って本サービスを利用してください。本サービスの利用中や変更、中断、終了などによって生じた不利益や損害について、当社は一切責任を負いません。機器の故障、人災、天災などによってサービス提供が中断、停止、提供情報の遅配などが生じた場合も同様です。また、本サービス利用者が本サービスを利用することによって生じた他の本サービス利用者や講師との紛争やトラブルについても、当社は一切責任をただし負いません。
        </p>

        <h3 class="subttl">損害賠償</h3>
        <p>
          本サービス利用者が本利用規約又は個別相談契約に反した行為又はその他の違法な行為をすることによって当社又は講師が損害を受けた場合、当社は本サービス利用者に対して、損害賠償請求（合理的範囲の弁護士費用を含む）ができるものとし、本サービス利用者は、当該損害を賠償するものとします。
        </p>

        <h3 class="subttl">協議事項</h3>
        <p>
          本利用規約に定めのない事項について､当社と本サービス利用者との間に疑義が生じた場合には､双方誠意をもって協議解決するものとします｡
        </p>

        <h3 class="subttl">準拠法および管轄裁判所</h3>
        <p>
          本利用規約の解釈にあたっては、日本法を準拠法とします。本サービスに関して紛争が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </p>

        <p>
          付則<br/>
          施行 2024年1月15日
        </p>
      </section>
    </div>
    <MetaTags />
  </div>
</template>
<script>
export default {
  name: "Terms",
};
</script>
<style scoped></style>
